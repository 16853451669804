<ion-content>
  <a href="javascript:void(0)" class="close" (click)="dismiss()">
    <ion-icon name="close-outline"></ion-icon>
  </a>
  <div class="wide-viewport-container padding-16">
    <div
      class="instruction-text margin-top-48 margin-bottom-48 font-color-primary"
    >

    </div>
    <div class="text-align-center">
      <bh-app-logo color="var(--font-color)" size="medium"></bh-app-logo>
    </div>
    <h1 class="text-align-center">
      Welcome to the {{ env?.appDescription }}
    </h1>
    <div class="headline-text">
      Easily find and contact Baystate Health employees.
    </div>
  </div>
  <div class="shaded">
    <div class="wide-viewport-container padding-16">
      <h4 style="margin-top: 48px; margin-bottom: 48px">
        Here are the features to know!
      </h4>
      <div class="visual-step">
        <div class="visual-step-text">
          <span>
            <strong>1. Find the Employee</strong><br />
            In the field, type the name of the person you want to contact.
          </span>
        </div>
        <div class="visual-step-image step1"></div>
      </div>

      <div class="visual-step">
        <div class="visual-step-text">
          <span>
            <strong>2. Select the Employee</strong><br />
            As you begin to type, name options will appear. You can scroll down
            to select the employee or finish typing their complete name so that
            only they appear. Then, tap the name of the employee to select them.
          </span>
        </div>
        <div class="visual-step-image step2"></div>
      </div>

      <div class="visual-step">
        <div class="visual-step-text">
          <span>
            <strong>3. Contact the Employee</strong><br />
            On the employee’s profile page, you’ll see options for how to
            contact them and where at Baystate they are located. To contact
            them, tap the communication method you want to use, e.g. call, send
            a page, or email.
          </span>
          <span class="tip-text">
            PRO TIP: <br>
            Click the star in the upper right corner to make that
            person a “Favorite.” (If the star is yellow, they are a Favorite.)
            That will save the employee under the “My Favorites” star icon on
            the app homepage so you can contact them more easily next time.
          </span>
        </div>
        <div class="visual-step-image step3"></div>
      </div>

      <div class="visual-step">
        <div class="visual-step-text">
          <span>
            <strong>4. Complete the Communication</strong><br />
            Once you decide how you want to contact the employee, complete the
            communication. In this case, when sending a page, you need to type
            your message and then click the blue “Send page” button at the
            bottom of your screen. Next, you’ll see a confirmation screen when
            the page has been successfully sent.
          </span>
        </div>
        <div class="visual-step-image step4"></div>
      </div>

      <div class="visual-step">
        <div class="visual-step-text">
          <span>
            <strong>5. Contacting Employees in Groups</strong><br />
            Some employees have access to pre-created Groups. If you see Groups,
            just click on the group you want to access, select the employee or
            generic group, and follow the same steps as above.
          </span>
        </div>
        <div class="visual-step-image step5a"></div>
        <div class="visual-step-image step5b"></div>
      </div>

      <div class="visual-step">
        <div class="visual-step-text">
          <span>
            <strong
              >6. Access Your Account, Get Help, and Other Useful
              Features</strong
            ><br />
            Click the circle icon with your initials in the upper right corner
            of the home screen. A menu will appear with several options as shown
            below. Under “Get Help” you can access visual training and app
            support for Employee Directory anytime.
          </span>
          <span class="tip-text">
            PRO TIP: <br>
            Under “Account” you can now reset your network password or
            set your password security questions, which all employees should do.
          </span>
        </div>
        <div class="visual-step-image step6"></div>
      </div>
    </div>
  </div>
  <div class="button-footer">
    <ion-button class="standard-cta" (click)="dismiss()"
      >Get Started</ion-button
    >
  </div>
</ion-content>
