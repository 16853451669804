import { UserDevice } from '../../models/_core/user-device';
import { UserDeviceService } from './../_core/user-device/user-device.service';
import { Platform, NavController } from '@ionic/angular';
import { AuthService } from './../_core/auth/auth.service';
import { NavPage } from './../../models/_core/nav-page';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';

/**
 * ID: bh-navigation
 * Name: BH Navigation Service
 * Description: Service used for managing navigation, main menus, and account options
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2022-15-25 - MW - v1: Initial development
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  // Define your pages for this app here
  navPages: NavPage[] = [
    {
      name: 'Home',
      navPath: '/tabs/home',
      tabId: 'home',
      ionIcon: 'home',
      roles: ['USER', 'ADMIN', 'SYS_ADMIN']
    },
    {
      name: 'Favorites',
      navPath: '/tabs/favorites',
      tabId: 'favorites',
      ionIcon: 'star',
      roles: ['USER', 'ADMIN', 'SYS_ADMIN']
    },
    {
      name: 'Recently Paged',
      navPath: '/tabs/recent-pages',
      tabId: 'recent-pages',
      ionIcon: 'time',
      roles: ['USER', 'ADMIN', 'SYS_ADMIN']
    },
    // {
    //   name: 'Manage',
    //   navPath: '/tabs/manage',
    //   tabId: 'manage',
    //   ionIcon: 'build',
    //   roles: ['ADMIN', 'SYS_ADMIN']
    // }
  ];
  showNotifications = false;
  subscriptions: Subscription[] = [];
  accessiblePages = 0;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private deviceService: UserDeviceService
  ) { }

  checkPrivileges(authUser: User) {
    this.navPages.forEach(page => {
      if (page.roles && page.roles.length > 0) {
        if (authUser) {
          // User logged in, check roles
          const matches = page.roles.filter(r => (r && authUser && authUser.role && r.toLowerCase() === authUser.role.toLowerCase()));
          page.isAccessible = (matches.length > 0);
        } else {
          // User not logged in
          page.isAccessible = false;
        }
      } else {
        // Page unprotected
        page.isAccessible = true;
      }
    });

    this.accessiblePages = this.navPages.filter(p => p.isAccessible).length;
  }

  navigateHome(): Promise<boolean> {
    return this.navigateBack('/tabs/home');
  }

  navigateForward(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    const userDevice = this.deviceService.getUserDevice();
    if(userDevice.isNarrowViewport) {
      return this.navCtrl.navigateForward(pathUrl, navOptions);
    } else {
      return this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateBack(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    const userDevice = this.deviceService.getUserDevice();
    if(userDevice.isNarrowViewport) {
      return this.navCtrl.navigateBack(pathUrl, navOptions);
    } else {
      return this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateRoot(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    return this.navCtrl.navigateRoot(pathUrl, navOptions);
  }

}
